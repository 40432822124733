/** Wrapper Styles **/

@font-face {
  font-family:'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'),
  url(../fonts/OpenSans-Regular.ttf) format('truetype'),
  url(../fonts/OpenSans-Light.ttf) format('truetype'),
  url(../fonts/OpenSans-Bold.ttf) format('truetype')
}
@font-face {
  font-family:'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans'),
  url(../fonts/OpenSans-Light.ttf) format('truetype')
}
@font-face {
  font-family:'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans'),
  url(../fonts/OpenSans-Bold.ttf) format('truetype')
}

body {

}


h1, h2, h3, h4, h5 {

}

h1, h2 {
  border-bottom: 1px solid $primary-color;
  padding-bottom: 1rem;
}

.main ul li,
.main p {
  font-size: rem-calc(20px);
}

a, i {
  transition: all 200ms linear;
}

div,
img {
  outline: none !important;
}

.wrapper {
	overflow: hidden;
}

.section {
	padding: 50px 0;
}

.bg-grau {
  background-color: #f7f5f4;
}

.bg-lila {
  background-color: $primary-color;
  border-top: 20px solid $secondary-color;
  color: $white;

  h1, h2 {
    border-bottom: 1px solid $white;
  }

  a {
    color: $white;
  }
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding {
	padding: 0 !important;
}