
@include breakpoint(1024px down) {
   .logo-wrapper {
     img {
       width: 85%;
     }
   }

  .header-top {
    font-size: rem-calc(14px);
  }

  .main-menu .menu li a {
    padding: 1.5rem 1.5rem;
  }
}

@include breakpoint(medium down) {

  .logo-mobil-wrapper {
    img {
      width: 75%;
    }
  }

  .cell h3 {
    font-size: rem-calc(20px);
  }

  .section {
    padding: 35px 0;
  }

  .main ul li,
  .main p {
    font-size: 100%;
  }

  .accordion-title {
    font-size: rem-calc(19px);
  }
  .accordion-title::before {
    top: 40%;
    font-size: rem-calc(20px);
  }
  .accordion-content {
    padding: 2rem 1.5rem;
  }

  .footer {
    text-align: center;
  }

  .slick-prev, .slick-next,
  #return-to-top {
    display: none !important;
  }
}

@include breakpoint(small down) {
  .accordion-title {
    font-size: rem-calc(16px);
  }
}
