.main-menu-wrapper {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  margin-bottom: 3rem;
}

.menu-icon::before {
  content: 'Menu';
  left: -50px;
  top: 1px;
  position: relative;
  display: inline-block;
  color: white;
  text-transform: uppercase;
}

.footer-menu,
.main-menu {

  .menu {
    li {
      a {
        padding: 1.5rem 3rem;
        font-size: rem-calc(22px);
        font-weight: 400;
        color: $black;
        border-bottom: 5px solid transparent;


        &.active,
        &:hover {
          color: $primary-color;
          border-bottom: 5px solid $secondary-color;
        }
      }

      &:first-child {
        a {
        }
      }

      &:last-child {
        a {
        }
      }

      &.is-active {
        > a {

        }
      }
    }
  }

  .is-dropdown-submenu-parent {
    position: static;
  }

  .is-dropdown-submenu.menu {
    width: 100%;

    li {
      text-align: left !important;
      a {
        color: $black;
      }
    }
  }
}

// Off-Canvas

.off-canvas-btn {
  padding: 1rem;
  text-align: right;
}


.off-canvas {
  padding-top: 3.5rem;

  li {
    display: block;
    width: 100%;

    a {
      border-right: 0 none;
      border-bottom: 0 none;
      padding: 1rem 0.8rem;
      text-transform: uppercase;
      color: $black;


      &.active,
      &:hover {
        background-color: transparent;
        border-bottom: 0 none;
      }
    }

    .menu {
      padding-left: 1rem;
    }
  }
}


a.imp {
  padding-right: 2rem;
}