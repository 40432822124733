.mobil-menu {
  background-color: $primary-color;
}


.menu-icon::before {
  content: 'Menu';
  left: -60px;
  top: 1px;
  position: relative;
  display: inline-block;
  color: white;
  text-transform: uppercase;
}