.header {

}

.header-top {
  background-color: $primary-color;
  color: $white;
  padding: 1rem 0;

  a {
    color: $white;
  }

  p {
    margin: 0;
  }

  img {
    padding: 0 2rem;
  }
}

.logo-wrapper {
  padding: 3rem 0;
}

.logo-mobil-wrapper {
  padding: 1.5rem 0;
}